import { BrowserRouter, Route } from "react-router-dom";

import Home from "../pages/Home";

export const Router = () => {
    return (
        <BrowserRouter>
            <Route path="/:cuid" exact component={Home} />
        </BrowserRouter>
    );
};
