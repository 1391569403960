import { useForm } from "../../context/forms";
import { useSettings } from "../../context/settings";

import * as C from "./styles";

export const Header = () => {
    const { form } = useForm();
    const settings = useSettings();

    return (
        <C.Container>
            <div>
                <img alt={settings?.settings?.subscriber.name} className="logo" src={settings?.settings?.logo_path} />
            </div>
            <div>
                <h1>{form?.name ?? "Carregando..."}</h1>
                <p>{form?.description}</p>
            </div>
        </C.Container>
    );
};
