import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    /* height: 120px; */
    border-bottom: 1px solid #16375c;

    padding: 25px;
    padding-left: 0px;
    padding-right: 0px;

    display: flex;
    gap: 25px;
    align-items: center;

    .logo {
        width: 70px;
    }

    h1 {
        /* padding: 30px 0 5px 30px; */
        color: #16375c;
        margin-bottom: 10px;

        font-size: 22px;
    }
    p {
        font-size: 14px;
        line-height: 16px;
        /* padding: 5px 0 10px 40px; */
    }
`;
