import * as C from "./styles";

export const SelectOption = ({ title, description, icon, selected, onClick }: Props) => {
    return (
        <C.Container onClick={onClick} selected={selected}>
            {icon ? <C.Icon>{icon}</C.Icon> : null}

            <C.Info>
                <C.Title>{title}</C.Title>
                {description ? <C.Description>{description}</C.Description> : null}
            </C.Info>
        </C.Container>
    );
};

type Props = {
    title: string;
    description?: string;
    icon?: string;
    selected: boolean;
    onClick?: () => void;
};
