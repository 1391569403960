import styled from "styled-components";

export const Container = styled.div`
    display: flex;

    margin-bottom: 10px;
    align-items: center;
    /* background-color: rgb(235, 235, 235); */
`;

export const Description = styled.div`
    font-size: 14px;
    line-height: 22px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
`;

export const Input = styled.input`
    display: block;
    margin-top: 7px;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 10px;
    border: 2px solid #16375c;
    border-radius: 10px;
    font-size: 28px;
    outline: 0;
    font-size: 15px;
    background-color: rgb(235, 235, 235);
`;

export const InputNote = styled.input`
    display: block;
    margin-top: 7px;
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    border: 1px solid #16375c;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    background-color: rgb(235, 235, 235);
`;
