import { HeadProvider, Title, Meta } from "react-head";

import { Router } from "./routes/router";

/** contexts */
import { FormProvider } from "./context/forms";
import { useSettings } from "./context/settings";

import GlobalStyled from "./components/styles/GlobalStyledComponents/GlobalStyled";

function App() {
    const settings = useSettings();
    return (
        <div>
            <HeadProvider>
                <FormProvider>
                    <Title>{settings?.settings?.homepage?.title ?? "Pontual Vistos"}</Title>
                    <Meta name="theme-color" content={settings?.settings?.homepage?.theme_color} />
                    <Meta name="description" content={settings?.settings?.homepage?.description} />
                    <Router />
                    <GlobalStyled />
                </FormProvider>
            </HeadProvider>
        </div>
    );
}

export default App;
