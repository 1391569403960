/* eslint-disable jsx-a11y/anchor-is-valid */
import * as C from "./styles";

import { useForm } from "../../context/forms";

type Props = {
    title: string;
    description: string;
    icon?: string;
    path: string;
    active: boolean;
    index: number;
};

export const SidebarItem = ({ title, description, icon, path, active, index }: Props) => {
    const { changeForm } = useForm();

    return (
        // onClick={() => changeForm({ activeSection: index })}
        <C.Container>
            <a>
                <C.Info>
                    <C.Title>{title}</C.Title>
                    <C.Description>{description}</C.Description>
                </C.Info>
                {icon ? <C.IconArea active={active}></C.IconArea> : null}

                <C.Point active={active}></C.Point>
            </a>
        </C.Container>
    );
};
