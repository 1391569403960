import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    border: 2px solid rgb(208, 180, 235);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    align-items: center;
    /* background-color: rgb(235, 235, 235); */
`;

export const Description = styled.div`
    font-size: 14px;
    line-height: 22px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
`;
