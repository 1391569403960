import * as C from "./styles";

export const Display = ({ description }: Props) => {
    return (
        <C.Container>
            <C.Description dangerouslySetInnerHTML={{ __html: description }} />
        </C.Container>
    );
};

type Props = {
    description: string;
};
