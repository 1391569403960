import React from "react";

import { useForm } from "../../context/forms";
import { SelectOption } from "../SelectOption";
import * as C from "./styles";

export const Radio = ({ field, index, sectionIndex }: Props) => {
    const { form, changeForm } = useForm();

    const [value, setValue] = React.useState(form?.sections ? form?.sections[sectionIndex]?.fields[index]?.values?.value : "");
    const [note, setNote] = React.useState(form?.sections ? form?.sections[sectionIndex]?.fields[index]?.values?.note : "");

    const changeValue = (text: string) => {
        let _record: any = { ...form };

        if (form && _record.sections && Array.isArray(_record.sections) && _record.sections[sectionIndex ?? 0]) {
            _record.sections[sectionIndex].fields[index].values = { ..._record.sections[sectionIndex].fields[index].values, value: text, field_id: field.id };

            // verifica se tem regras
            if (field.have_rules) {
                // percorre as regras
                field.rules.map(rule => {
                    // verifica se o valor selecionado pelo usuário é o mesmo da regra
                    if (rule.selected_value === text) {
                        if (rule.hide) {
                            rule.hide.target.map(target => {
                                // caso a regra seja passar para um campo, ou seja ocultar o da sequencia
                                if (target.type === "field") {
                                    // oculta o campo em questão
                                    _record.sections[sectionIndex ?? 0].fields[target.index].show = false;
                                    delete _record.sections[sectionIndex ?? 0].fields[target.index].values;
                                } else if (target.type === "section") {
                                    _record.sections[target.index].show = false;
                                    _record.sections[target.index].fields.map((ff: iField, ii: number) => {
                                        delete _record.sections[target.index].fields[ii].values;
                                    });
                                }
                            });
                        }

                        if (rule.show) {
                            rule.show.target.map(target => {
                                // caso a regra seja passar para um campo, ou seja ocultar o da sequencia
                                if (target.type === "field") {
                                    // oculta o campo em questão
                                    _record.sections[sectionIndex ?? 0].fields[target.index].show = true;
                                } else if (target.type === "section") {
                                    _record.sections[target.index].show = true;
                                }
                            });
                        }
                    }
                });
            }
        }

        changeForm(_record);
    };

    const changeNote = (text: string) => {
        let _record: any = { ...form };

        if (form && Array.isArray(_record.sections) && _record.sections[sectionIndex]) {
            _record.sections[sectionIndex].fields[index].values = { ..._record.sections[sectionIndex].fields[index].values, note: text, field_id: field.id };
        }

        changeForm(_record);
    };

    React.useEffect(() => {
        setValue(form?.sections ? form?.sections[sectionIndex]?.fields[index]?.values?.value : "");
        setNote(form?.sections ? form?.sections[sectionIndex]?.fields[index]?.values?.note : "");
    }, [field.id]);

    return (
        <>
            <div className="options">
                {field.options.map(option => (
                    <SelectOption
                        onClick={() => {
                            changeValue(option);
                            setValue(option);
                        }}
                        description={option === value ? "Selecionado" : "Clique para selecionar"}
                        title={option}
                        selected={option === value}
                    />
                ))}
            </div>
            {field.allows_notes ? (
                <C.InputNote
                    placeholder="Observações"
                    onBlur={e => changeNote(e.target.value)}
                    onChange={e => setNote(e.target.value)}
                    type={"text"}
                    value={note}
                />
            ) : null}
        </>
    );
};

type Props = {
    field: iField;
    index: number;
    sectionIndex: number;
};
