/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";

/** services */
import api from "../services/api";

/** utils */
import error_alert from "../utils/alerts/error";

export const SettingsContext = React.createContext<iSettingContext | null>(null);

export const SettingsProvider = (props: any) => {
    const [settings, setSettings] = React.useState<iSiteData | null>(null);

    const loadSetting = () => {
        api.get(`/public/sites`)
            .then(response => {
                if (response.data && response.data.id) {
                    api.get(`/public/pages/home`).then(res => {
                        const homepage = res.data;
                        localStorage.setItem("@pontualvistos_current_settings", JSON.stringify({ ...response.data, homepage }));
                        setSettings({ ...response.data, homepage });
                    });
                }
            })
            .catch(() => {
                error_alert("Site indisponível", "Não foi possível obter as informações do site.", () => {}, {
                    buttons: [false],
                    closeOnEsc: false,
                    closeOnClickOutside: false,
                });
            });

        const data = localStorage.getItem("@pontualvistos_current_settings");
        if (data) setSettings(JSON.parse(data));
    };

    const value = useMemo(() => ({ settings, loadSetting }), [settings, loadSetting]);

    React.useEffect(() => {
        loadSetting();
    }, []);

    return <SettingsContext.Provider value={value}>{props.children}</SettingsContext.Provider>;
};

export const useSettings = () => React.useContext<iSettingContext | null>(SettingsContext);
