import axios from "axios";

import config from "../config";

const baseURL = config.API_URL;
const api = axios.create({ baseURL });

api.interceptors.request.use(
    async (c: any) => {
        c.headers.authorization = config.SITE_ID;
        return c;
    },
    error => false,
);

api.interceptors.response.use(
    response => response,
    async error => {
        if (error?.response?.status && error?.response?.status === 401) {
        } else if (error?.response?.status && error?.response?.status === 422) {
            // if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
            //   let errors = "\n";
            //   error?.response?.data?.errors.map(item => {
            //     // console.log(data?.validation[item][0]?.message);
            //     errors += `* ${item?.message}\n\n`;
            //   });
            //   alert("Atenção!", errors);
            //   return false;
            // }
        }
        return Promise.reject(error);
    },
);

export default api;

export { baseURL };
