import styled from "styled-components";

export const Container = styled.div`
    background-color: whitesmoke;
    height: 100vh;
    overflow-y: scroll;
`;

export const Area = styled.div`
    width: 90%;
    margin: auto;
    height: 100vh;
`;

export const Steps = styled.div`
    width: 100%;
    display: flex;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

export const Sidebar = styled.div`
    min-width: 300px;
    width: 300px;
    border-right: 1px solid #16375c;

    @media (max-width: 1200px) {
        display: none;
    }
`;

export const Page = styled.div`
    /* width: 60%; */
    width: 100%;
`;
