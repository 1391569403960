import styled from "styled-components";

export const Container = styled.div`
    padding: 50px;
    flex: 1;

    @media (max-width: 1200px) {
        padding: 10px;
    }

    P {
        display: block;
    }

    h2 {
        display: block;
        margin-bottom: 5px;
        color: #16375c;
    }

    label {
        display: block;
        margin-top: 40px;
        margin-bottom: 5px;
        line-height: 24px;
    }

    button {
        border-radius: 10px;
        background-color: #16375c;
        color: whitesmoke;
        border: none;
        margin-top: 20px;

        padding: 20px 30px;

        margin-right: 10px;

        cursor: pointer;

        &.back {
            background-color: rgb(235, 235, 235);
            color: #000;
        }

        &.next {
            font-weight: bold;
            font-size: 14px;
        }
        &.send {
            background-color: green;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
        }
    }

    .passo {
        margin-bottom: 20px;
    }

    .input {
        display: block;
        margin-top: 7px;
        box-sizing: border-box;
        width: 100%;
        padding: 20px 10px;
        border: 2px solid #16375c;
        border-radius: 10px;
        font-size: 28px;
        outline: 0;
        font-size: 15px;
        background-color: rgb(235, 235, 235);
    }

    .options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        gap: 10px;

        @media (max-width: 1200px) {
            flex-direction: column;
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;
