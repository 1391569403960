import * as C from "./styles";
import { ReactNode } from "react";

import { Header } from "../Header";
import { SidebarItem } from "../SidebarItem";

/** context */
import { useForm } from "../../context/forms";

export const Theme = ({ children }: Props) => {
    const { form } = useForm();

    return (
        <C.Container>
            <C.Area>
                <Header />
                <C.Steps>
                    <C.Sidebar>
                        {form?.sections?.map((section, index) => (
                            <>
                                {section.show !== false ? (
                                    <SidebarItem
                                        index={index}
                                        key={`section-${section.id}`}
                                        title={section.name}
                                        description={section.description}
                                        path="/"
                                        active={form.activeSection === index}
                                    />
                                ) : null}
                            </>
                        ))}
                    </C.Sidebar>
                    <C.Page>{children}</C.Page>
                </C.Steps>
            </C.Area>
        </C.Container>
    );
};

type Props = {
    children: ReactNode;
};
