import swal from "sweetalert";

export default function loading_alert(title: string, msg = "") {
    if (swal && swal.getState && swal.close && swal.getState().isOpen) swal.close();

    swal({
        title,
        text: msg,
        dangerMode: true,
        closeOnClickOutside: false,
        buttons: [false],
        closeOnEsc: false,
    });
}
